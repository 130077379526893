<script setup>
import { onMounted, watch } from 'vue'
import CenterMap from './components/center-map.vue'
import MapBaselayers from './components/map-baselayers.vue'
import MapPolygons from './components/map-polygons.vue'
import { useTableStore } from '@/store/table'
import { useMainStore } from '@/store'
import { usePointsLayer } from './composables/points-layer'
import { usePolygonsLayer } from './composables/polygons-layer'
import { useMap } from './composables/map'
import { centerMap } from './utils'
import PolygonTooltip from '@/components/map/components/polygon-tooltip.vue'

const tableStore = useTableStore()
const mainStore = useMainStore()
const { mapgl } = useMap()

onMounted(() => {
  const { addPoints } = usePointsLayer(mapgl)
  const { loadAndAddPolygonsLabels } = usePolygonsLayer(mapgl)

  if (!mapgl.value) return

  mapgl.value.on('load', async () => {
    await loadAndAddPolygonsLabels()
    addPoints()
    centerMapHandler()

    mainStore.toggleLoading(false)
  })

  window.addEventListener('resize', updateResizeMap)
})

const updateResizeMap = () => {
  mapgl.value.resize()
}

watch(
  () => mainStore.centerPoint,
  (rowID) => {
    if (rowID !== null) {
      const foundItem = tableStore.pointsList.find(
        (point) => point.id === rowID
      )

      if (!foundItem.lon || !foundItem.lat) return

      centerMap(mapgl.value, [foundItem])
    }
    mainStore.changeCenterPoint(null)
  }
)

const centerMapHandler = () => {
  centerMap(mapgl.value, tableStore.pointsList)
}
</script>
<template>
  <div
    id="map"
    class="mapbox"
  />
  <template v-if="mapgl">
    <map-polygons />
    <polygon-tooltip />
    <map-baselayers :mapgl="mapgl" />
    <center-map @click="centerMapHandler" />
  </template>
</template>
<style lang="scss">
.mapbox {
  width: 100%;
  height: 100%;

  .mapboxgl-map {
    height: 100%;
    width: 100%;
  }

  & .mapboxgl-ctrl-bottom-left {
    display: none;
  }

  .mapboxgl-canvas {
    outline: none;
  }

  & .mapboxgl-ctrl-bottom-right {
    display: none;
  }

  .mapboxgl-ctrl-top-right {
    right: 16px;
    top: 50%;
    transform: translateY(-50%);

    .mapboxgl-ctrl {
      margin: 0;
      border-radius: 8px;
    }

    .mapboxgl-ctrl-group {
      background-color: var(--bg);
      box-shadow: none;

      .mapboxgl-ctrl-zoom-in {
        background-image: url('@/assets/icons/plus_dark.svg');
      }

      .mapboxgl-ctrl-zoom-out {
        background-image: url('@/assets/icons/minus_dark.svg');
      }

      .mapboxgl-ctrl-zoom-in {
        border: 1px solid var(--main-bg) !important;
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;
      }

      .mapboxgl-ctrl-zoom-out {
        border: 1px solid var(--main-bg) !important;
        border-top: none !important;
        border-bottom-left-radius: 8px;
        border-bottom-right-radius: 8px;
      }

      button {
        width: 40px;
        height: 40px;
        border: none;
        background-repeat: no-repeat;
        background-position: center;
        background-size: 40%;

        .mapboxgl-ctrl-icon {
          background-image: none !important;
        }
      }
    }
  }
}
</style>
