import { nextTick, onMounted, ref, watch } from 'vue'
import mapboxgl from 'mapbox-gl'
import { accessToken, mapStyles } from '@/config/map'
import 'mapbox-gl/dist/mapbox-gl.css'

import { useMainStore } from '@/store'

export const useMap = () => {
  const mapgl = ref(null)
  const mainStore = useMainStore()

  onMounted(() => {
    mapboxgl.accessToken = accessToken

    mapgl.value = new mapboxgl.Map({
      container: 'map',
      style: mapStyles['default'],
      center: [97, 61],
      zoom: 2
    })

    mapgl.value.addControl(
      new mapboxgl.NavigationControl({
        showCompass: false
      })
    )
  })

  watch(
    () => mainStore.isShowTable,
    async () => {
      mapgl.value.resize()
    }
  )

  return { mapgl }
}
