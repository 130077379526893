import { watch, inject } from 'vue'
import { columnsConfig, decimalColumnsConfig } from '@/config/table'
import { saveDataToLS, splitString } from '@/utils'
import { checkObjectFieldsAreEmpty } from '../helpers/index'
import { useMapStore } from '@/store/map'
import { useTableStore } from '@/store/table'
import { useMainStore } from '@/store'
import { getZone } from '@/components/table/helpers'
import useAxios from '@/composables/useAxios'

export const useDataTable = (table) => {
  const mapStore = useMapStore()
  const tableStore = useTableStore()
  const mainStore = useMainStore()
  const $notify = inject('$notify')
  const $axios = useAxios()
  const zoneNotFoundStatus = 'zone_not_found'
  let changedRows = []

  const ranges = {
    lat: [-90, 90],
    lon: [-180, 180]
  }

  watch(
    () => tableStore.selectValue,
    (newValue) => {
      if (newValue.id === 'WGS-MSK') {
        addMapZonesToWGSMSKColumns()
      }

      if (newValue.id === 'MSK-WGS') {
        addMapZonesToMSKWGSColumns()
      }
    }
  )

  function addMapZonesToWGSMSKColumns() {
    let zoneColumnWGSMSK = null

    zoneColumnWGSMSK = columnsConfig.find((column) => column.data === 'zone_in')

    if (zoneColumnWGSMSK && mapStore.mapZones) {
      zoneColumnWGSMSK.source = mapStore.mapZones.map(
        (value) => value.fullZoneName
      )
    }
  }

  function addMapZonesToMSKWGSColumns() {
    let zoneColumnMSKWGS = null

    zoneColumnMSKWGS = decimalColumnsConfig.find(
      (column) => column.data === 'zone_in'
    )

    if (zoneColumnMSKWGS && mapStore.mapZones) {
      zoneColumnMSKWGS.source = mapStore.mapZones.map(
        (value) => value.fullZoneName
      )
    }
  }

  watch(
    () => mapStore.mapZones,
    (newValue) => {
      addSourceToColumns(newValue)

      table.value.updateSettings({
        data: tableStore.pointsList,
        autofill: {
          autoInsertRow: false
        },
        viewportRowRenderingOffset: 70,
        renderAllRows: true,
        afterChange: afterChangeData,
        afterSelection: afterSelection,
        afterAutofill: afterAutofill
      })
    }
  )

  function addSourceToColumns(zones) {
    const zoneColumnMSKWGS = decimalColumnsConfig.find(
      (column) => column.data === 'zone_in'
    )

    zoneColumnMSKWGS.source = zones.map((value) => value.fullZoneName)

    const zoneColumnWGSMSK = columnsConfig.find(
      (column) => column.data === 'zone_in'
    )

    zoneColumnWGSMSK.source = zones.map((value) => value.fullZoneName)
  }

  watch(
    () => mainStore.activePoint,
    (newValue) => {
      table.value.selectRows(newValue - 1)
      mainStore.SET_STATE_FIELD('activePoint', null)
    }
  )

  function checkCells(row, col, prop) {
    if (!tableStore?.pointsList) return

    const cellProperties = {}
    const selectValue = tableStore.selectValue

    if (selectValue.id === 'WGS-MSK') {
      Object.assign(cellProperties, checkWGSMSKCells(row, cellProperties, prop))
    }

    if (selectValue.id === 'MSK-WGS') {
      Object.assign(cellProperties, checkMSKWGSCells(row, cellProperties, prop))
    }

    const isEmpty = checkObjectFieldsAreEmpty(tableStore?.pointsList[row])

    if (isEmpty) {
      cellProperties.className = 'htMiddle htCenter'
    }

    return cellProperties
  }

  function checkMSKWGSCells(row, cellProperties, prop) {
    const x = table.value.getDataAtRowProp(row, 'x')
    const y = table.value.getDataAtRowProp(row, 'y')
    const zone_in = table.value.getDataAtRowProp(row, 'zone_in')
    const id = table.value.getDataAtRowProp(row, 'id')

    if (!id) {
      const columnsToDisable = ['centerPoint', 'zone_in']

      if (columnsToDisable.includes(prop)) {
        cellProperties.readOnly = true
        cellProperties.renderer = (instance, TD) => {
          TD.innerHTML = ''
        }
      }
    }

    if (!x || !y || !zone_in) {
      if (prop === 'id') {
        cellProperties.className = 'invalid htMiddle htCenter'
      }

      const columnsToDisable = ['centerPoint']

      if (columnsToDisable.includes(prop)) {
        cellProperties.readOnly = true
        cellProperties.renderer = (instance, TD) => {
          TD.innerHTML = ''
        }
      }
    }

    return cellProperties
  }

  function checkWGSMSKCells(row, cellProperties, prop) {
    const latitude = table.value.getDataAtRowProp(row, 'lat')
    const longitude = table.value.getDataAtRowProp(row, 'lon')

    if (!latitude || !longitude) {
      if (prop === 'id') {
        cellProperties.className = 'invalid htMiddle htCenter'
      }

      const columnsToDisable = ['centerPoint', 'zone_in']

      if (columnsToDisable.includes(prop)) {
        cellProperties.readOnly = true
        cellProperties.renderer = (instance, TD) => {
          TD.innerHTML = ''
        }
      }

      Object.assign(
        cellProperties,
        checkLatLotCellsValues(latitude, longitude, prop, row)
      )
    }

    return cellProperties
  }

  function checkLatLotCellsValues(lat, lon, prop, row) {
    const cellProperties = {}

    const WGS_COORDS = ['lat', 'lon']

    if (WGS_COORDS.includes(prop)) {
      if (tableStore?.pointsList[row]?.status === zoneNotFoundStatus) {
        cellProperties.className = 'invalid htMiddle htCenter'
      }

      if (isOutOfRange(prop, lat)) {
        cellProperties.className = 'invalid htMiddle htCenter'
      }
    }

    return cellProperties
  }

  const coordsToCheckRange = ['lat', 'lon']

  function isOutOfRange(prop, value) {
    if (!coordsToCheckRange.includes(prop)) return

    if (!value) return true

    let [min, max] = ranges[prop]
    return value < min || value > max
  }

  async function afterChangeData(changes, source) {
    const tableStore = useTableStore()

    const arrayOfActionsToRequests = [
      'edit',
      'CopyPaste.paste',
      'CopyPaste.cut',
      'UndoRedo.undo',
      'UndoRedo.redo',
      'Autofill.fill'
    ]

    const coordsFieldsMap = {
      lat: 'lon',
      lon: 'lat',
      x: 'y',
      y: 'x'
    }

    const mapToRequestsMSKWGS = ['zone_in', 'x', 'y']
    const mapToRequestsWGSMSK = ['zone_in', 'lat', 'lon', 'h']

    const cellsToUpdate = []

    const dataToClearRow = ['num', 'lat', 'lon', 'h', 'x', 'y', 'zone_in']

    const data = []

    const selectValue = tableStore.selectValue

    if (arrayOfActionsToRequests.includes(source)) {
      for (let i = 0; i < changes.length; i++) {
        const [row, changedField, _, newValue] = changes[i]

        if (!tableStore.pointsList[row]?.id) {
          setIDToPoint(row)
        }

        if (coordsFieldsMap[changedField]) {
          const secondCoords = table.value.getDataAtRowProp(
            row,
            coordsFieldsMap[changedField]
          )

          const className =
            !secondCoords || newValue === ''
              ? 'htMiddle htCenter invalid'
              : 'htMiddle htCenter'

          if (!isOutOfRange(changedField, newValue)) {
            const colIndex = table.value.propToCol(
              coordsFieldsMap[changedField]
            )

            cellsToUpdate.push({ row, col: colIndex, className })
          }

          cellsToUpdate.push({ row, col: 0, className })
        }

        if (selectValue.id === 'WGS-MSK') {
          if (mapToRequestsWGSMSK.includes(changedField)) {
            if (
              tableStore.pointsList[row].lat === null &&
              tableStore.pointsList[row].lon === null
            ) {
              clearWGSMSKFields(row)
            }

            if (
              tableStore.pointsList[row].lat &&
              tableStore.pointsList[row].lon
            ) {
              data.push(tableStore.pointsList[row])
            }
          }
        } else {
          if (mapToRequestsMSKWGS.includes(changedField)) {
            if (
              tableStore.pointsList[row].x === null &&
              tableStore.pointsList[row].y === null &&
              tableStore.pointsList[row].zone_in === null
            ) {
              clearMSKWGSFields(row)
            }

            if (
              tableStore.pointsList[row].x &&
              tableStore.pointsList[row].y &&
              tableStore.pointsList[row].zone_in
            ) {
              data.push(tableStore.pointsList[row])
            }
          }
        }

        if (dataToClearRow.includes(changedField)) {
          const results = dataToClearRow.map((point) => {
            const value = tableStore.pointsList[row][point]
            return !value
          })

          const isEmptyRow = results.every((result) => result)

          if (isEmptyRow) {
            tableStore.pointsList[row] = {}
          }
        }
      }

      if (cellsToUpdate.length > 0) {
        table.value.updateSettings({
          cell: cellsToUpdate
        })
      }

      const uniqueArray = getUniqueArrayOfObjects(data)

      await requestData(uniqueArray)
    }

    saveDataToLS('pointsList', tableStore.pointsList)

    if (table.value) {
      table.value.render()
    }
  }

  function clearWGSMSKFields(row) {
    tableStore.pointsList[row].x = null
    tableStore.pointsList[row].y = null
    tableStore.pointsList[row].zone_in = null
  }

  function clearMSKWGSFields(row) {
    tableStore.pointsList[row].lon = null
    tableStore.pointsList[row].lat = null
    tableStore.pointsList[row].zone_in = null
  }

  function setIDToPoint(row) {
    tableStore.pointsList[row].id = row + 1
  }

  function getUniqueArrayOfObjects(data) {
    const jsonObject = data.map((item) => JSON.stringify(item))
    const uniqueSet = new Set(jsonObject)
    const uniqueArray = Array.from(uniqueSet).map((item) => JSON.parse(item))

    return uniqueArray
  }

  async function requestData(changedItems) {
    if (!changedItems.length) return
    const selectValue = tableStore.selectValue
    tableStore.SET_STATE_FIELD('isLoadingDataSheets', true)

    changedItems.forEach((item) => {
      if (!item.h) {
        item.h = 0
      }

      if (item.zone_in) {
        const zone = getZone(item.zone_in)
        if (!zone) return
        item.zone_in = zone.id
      }
    })

    let url = null

    if (selectValue.id === 'WGS-MSK') {
      url = 'api/v1/converter/wgs2msk'
    } else {
      url = 'api/v1/converter/msk2wgs'
    }

    try {
      const { data } = await $axios.put(url, changedItems, {
        headers: {
          accept: 'application/json',
          'Content-Type': 'application/json'
        }
      })

      if (!data.length) return

      checkZoneNotFound(data)

      updateDataInPointsList(changedItems, data)
    } catch (e) {
      console.log(e)

      clearTable(changedItems)

      $notify({
        message: 'При перёсчете координат произошла ошибка',
        type: 'error'
      })
    } finally {
      tableStore.SET_STATE_FIELD('isLoadingDataSheets', false)
      changedRows = []

      table.value.updateSettings({
        cells: checkCells
      })
    }
  }

  function checkZoneNotFound(data) {
    if (data.some((item) => item.status === zoneNotFoundStatus)) {
      $notify({
        message: 'Некоторые координаты не попадают в зону пересчета',
        type: 'warning'
      })
    }
  }

  function updateDataInPointsList(changedItems, serverData) {
    changedItems.forEach((item, index) => {
      let zone
      const itemIndex = item.id - 1
      if (!item.id) return

      if (!tableStore.pointsList[itemIndex].zone_in) {
        zone = getZone(serverData[index].zone_out)
      } else {
        zone = getZone(tableStore.pointsList[itemIndex].zone_in)
      }

      tableStore.pointsList[itemIndex].zone_in = zone?.fullZoneName

      tableStore.pointsList[itemIndex].x = serverData[index].x
      tableStore.pointsList[itemIndex].y = serverData[index].y
      tableStore.pointsList[itemIndex].lat = serverData[index].lat.toFixed(6)
      tableStore.pointsList[itemIndex].lon = serverData[index].lon.toFixed(6)
      tableStore.pointsList[itemIndex].status = serverData[index].status
    })
  }

  function addRow() {
    const col = table.value.countRows()
    table.value.alter('insert_row_below', col, 1)
    table.value.render()
  }

  function afterSelection(startRow, _, endRow) {
    const selectedIds = []

    if (startRow > endRow) {
      const tmp = startRow
      startRow = endRow
      endRow = tmp
    }

    for (let row = startRow; row <= endRow; row++) {
      const id = this.getDataAtRowProp(row, 'id')
      const lat = this.getDataAtRowProp(row, 'lat')
      const lon = this.getDataAtRowProp(row, 'lon')

      if (lat && lon) {
        selectedIds.push(id)
      }
    }

    mainStore.setHighlightPointsID(selectedIds)
  }

  function clearTable(changedItems) {
    const cellsToUpdate = []
    changedItems.forEach((item) => {
      const itemIndex = item.id - 1
      const propIndex = table.value.propToCol('zone_in')

      if (tableStore.pointsList[itemIndex]) {
        tableStore.pointsList[itemIndex].x = null
        tableStore.pointsList[itemIndex].y = null
        tableStore.pointsList[itemIndex].lat = null
        tableStore.pointsList[itemIndex].lon = null
        tableStore.pointsList[itemIndex].zone_in = null
        tableStore.pointsList[itemIndex].zone_out = null
        tableStore.pointsList[itemIndex].zone_out_name = null
      }

      cellsToUpdate.push({
        row: itemIndex,
        col: propIndex,
        editor: false,
        readOnly: true,
        renderer: (instance, TD) => {
          TD.innerHTML = ''
        }
      })

      changedRows.push(itemIndex)
    })

    table.value.updateSettings({
      cells: checkCells,
      cell: cellsToUpdate
    })

    table.value.render()
  }

  function afterAutofill(selectionData, sourceRange, targetRange, direction) {
    if (
      table.value.getColHeader(sourceRange?.from.col) === 'Номер' &&
      direction === 'down'
    ) {
      const data = selectionData[0][0]
      const splittedStr = splitString(data)

      if (!splittedStr) return

      table.value.batch(() => {
        for (let i = sourceRange?.from.row + 1; i <= targetRange.to.row; i++) {
          for (let j = splittedStr.length - 1; j >= 0; j--) {
            const number = parseInt(splittedStr[j])
            if (!isNaN(number)) {
              splittedStr[j] = (number + 1).toString()
              const resultString = splittedStr.join('')
              table.value.setDataAtCell(i, 2, resultString)
              break
            }
          }
        }
      })
    }
  }

  return {
    addRow,
    checkCells
  }
}
